import { AllocationUsage } from '@/components/Timeline/allocation/types';
import {
    GetSectionsDataForAllocationDesignerTimelineDocument,
    TimelineAreaFragment,
    TimelineFullSiteFragment,
    TimelineRoomFragment,
} from '@/generated/graphql';
import { useQuery } from '@vue/apollo-composable';
import { DateTime } from 'luxon';
import { computed, Ref, ref, toValue } from 'vue';

const EMPTY_SITE = {
    id: '',
    nameShort: '',
    buildings: {
        nodes: [],
    },
    zones: {
        nodes: [],
    },
};

export function useDesignerTimelineData(startDateTime: Ref<DateTime>, endDateTime: Ref<DateTime>) {
    const rooms: Ref<TimelineRoomFragment[]> = ref([]);
    const areas: Ref<TimelineAreaFragment[]> = ref([]);
    const site: Ref<TimelineFullSiteFragment> = ref(EMPTY_SITE);
    const usages: Ref<AllocationUsage[]> = ref([]);

    const { loading, error, result, onResult } = useQuery(
        GetSectionsDataForAllocationDesignerTimelineDocument,
        computed(() => ({
            startDateTime: toValue(startDateTime).toISO(),
            endDateTime: toValue(endDateTime).toISO(),
        })),
    );

    onResult((result) => {
        if (result.error) {
            throw new Error('Error on receiving project timeline data');
        }
        const data = result.data;
        if (data) {
            rooms.value = data.rooms?.nodes ?? [];
            areas.value = data.areas?.nodes ?? [];
            const sites = data.sites?.nodes ?? [];
            if (!sites) throw new Error('Missing site');
            site.value = sites[0];
        }
    });

    return { loading, error, result, onResult, site, rooms, areas, usages };
}
