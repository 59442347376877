import { $t } from '@/plugins/fluent';

export const DEV_ENVS = ['local', 'dev', 'devdb'];

export function getEnvironmentShort(envName: string): string {
    switch (envName) {
        case 'dev':
            return $t('environment-dev-short');

        case 'stage':
            return $t('environment-stage-short');

        case 'devdb':
            return $t('environment-devdb-short');

        case 'local':
            return $t('environment-local-short');

        case 'prod':
            return $t('environment-prod-short');

        default:
            return envName;
    }
}

export function getEnvironmentLong(environment: string): string {
    switch (environment) {
        case 'dev':
            return $t('environment-dev-long');

        case 'stage':
            return $t('environment-stage-long');

        case 'devdb':
            return $t('environment-devdb-long');

        case 'local':
            return $t('environment-local-long');

        case 'prod':
            return $t('environment-prod-long');

        default:
            return environment;
    }
}
