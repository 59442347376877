<template>
    <section class="flex flex-row gap-2">
        <div class="px-1 flex flex-col items-center">
            <div
                class="flex place-items-center place-content-center bg-gray-200 text-gray-600 rounded-full w-6 h-6 shrink-0"
            >
                <TaskCircleIcon class="text-gray-600 w-3 h-3" />
            </div>

            <div class="border-l border-l-solid border-l-gray-300 h-full w-px mt-2 translate-x-[0.5px]" />
        </div>

        <div class="flex flex-col pb-2">
            <p class="text-sm text-gray-500 font-light leading-tight mb-0.5">
                <i18n path="user-changed-status">
                    <template #name>
                        <span class="font-medium text-gray-700">{{ props.userName }}</span>
                    </template>

                    <template #oldStatus>
                        {{ props.previousStatus }}
                    </template>

                    <template #newStatus>
                        <span class="font-medium text-gray-700">
                            {{ props.newStatus }}
                        </span>
                    </template>
                </i18n>
            </p>

            <time :datetime="props.dateTime.toISODate()" class="text-xs font-light text-gray-500">
                {{ props.dateTime.toLocaleString() }}
            </time>
        </div>
    </section>
</template>

<script setup lang="ts">
import { DateTime } from 'luxon';
import TaskCircleIcon from '../Icon/TaskCircleIcon.vue';

type StatusChangeEntryProps = {
    userName: string;
    previousStatus: string;
    newStatus: string;
    dateTime: DateTime;
};

const props = defineProps<StatusChangeEntryProps>();
</script>
