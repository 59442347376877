<template>
    <article class="bg-gray-50 rounded-lg shadow fading-out-highlight" :aria-labelledby="props.headlineId">
        <header
            class="p-4 flex flex-row items-center justify-between gap-3"
            :class="{
                'border-b border-b-gray-200':
                    props.expandableStatus === 'Expanded' || props.expandableStatus === 'Not-Collapsible',
            }"
        >
            <Button
                v-if="props.expandableStatus == 'Expanded' || props.expandableStatus === 'Collapsed'"
                size="Small"
                variant="Transparent-Light"
                :icon-description="
                    props.expandableStatus === 'Expanded' ? $t('card-hide-details') : $t('card-show-details')
                "
                :disabled-explanation="props.disabledExplanationExpandableButton"
                :aria-expanded="props.expandableStatus === 'Expanded'"
                :aria-controls="bodyId"
                @click="$emit('changeExpand')"
            >
                <template #icon>
                    <ChevronRightIcon
                        class="w-5 h-5 transition-transform"
                        :class="[props.expandableStatus === 'Expanded' ? '-rotate-90' : 'rotate-90']"
                    />
                </template>
            </Button>

            <slot name="header" v-bind="{ headlineId: props.headlineId }" />
        </header>

        <div v-if="props.expandableStatus !== 'Collapsed'" :id="bodyId" class="flex flex-col">
            <slot name="body" />
        </div>
    </article>
</template>

<script setup lang="ts">
import { nanoid } from 'nanoid';
import Button from '../Button/Button.vue';
import ChevronRightIcon from '../Icon/ChevronRightIcon.vue';

export type ExpandStatus = 'Not-Collapsible' | 'Expanded' | 'Collapsed';

type FlexibleCardProps = {
    headlineId: string; // Implemented as prop so we can use this for site internal links
    expandableStatus: ExpandStatus;
    disabledExplanationExpandableButton?: string;
};

const props = defineProps<FlexibleCardProps>();

defineEmits<{
    (e: 'changeExpand'): void;
}>();

const bodyId = nanoid();
</script>

<style>
.fading-out-highlight:has(.is-card-headline-target),
.fading-out-highlight:has(:target) {
    animation: ease-in-out fading-out-border 5s;
}

@keyframes fading-out-border {
    0% {
        box-shadow: 0 0 0 0.25rem theme('colors.sky.700');
    }

    100% {
        @apply shadow;
    }
}
</style>
