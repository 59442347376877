<template>
    <h2
        :id="props.headlineId"
        ref="cardHeadline"
        class="text-base font-semibold leading-[1.875rem] flex flex-row items-center gap-2"
    >
        <slot />

        <HelpButton v-if="props.helpText" :text="props.helpText" />
    </h2>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import HelpButton from '../HelpButton/HelpButton.vue';

type CardHeadlineProps = {
    headlineId: string; // Implemented as prop so we can use this for site internal links
    helpText?: string;
};

const props = defineProps<CardHeadlineProps>();

const cardHeadline = ref();
const route = useRoute();

watchEffect(() => {
    const hash = route.hash.replace('#', '');
    if (hash && cardHeadline.value && hash === cardHeadline.value.id) {
        cardHeadline.value.scrollIntoView({ block: 'center', behavior: 'smooth' });
        cardHeadline.value.classList.add('is-card-headline-target');
    }
});
</script>
