import { computed, Ref, toValue } from 'vue';

export const MINIMAL_YEAR = 2000;
export const MAXIMAL_YEAR = 2099;

export function useTimelineIntervalChanger(timelineDataLoading: Ref<boolean>, allocationsCount: Ref<number>) {
    const warningNoAllocations = computed(() => {
        if (toValue(timelineDataLoading) || toValue(allocationsCount) > 0) {
            return null;
        }

        return {
            buttonJumpToPreviousAllocations: 'Hidden' as const,
            buttonJumpToNextAllocations: 'Hidden' as const,
        };
    });

    return {
        warningNoAllocations,
    };
}
