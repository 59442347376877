<template>
    <div class="flex flex-col drop-shadow-md z-50">
        <slot name="review-section" />

        <NavigationSection>
            <slot name="navigation-section" />
        </NavigationSection>

        <ContentSection
            :left-compensation-in-rem="props.leftCompensationInRem"
            :right-compensation-in-rem="props.rightCompensationInRem"
            :style="{
                height: `${allocationHeightInRem / 2 + 0.25 + 1}rem`,
            }"
        >
            <slot name="content-section" />
        </ContentSection>
    </div>
</template>

<script setup lang="ts">
import { allocationHeightInRem } from '../sharedStyles';
import ContentSection from './ContentSection.vue';
import NavigationSection from './NavigationSection.vue';

type EditPopupProps = {
    leftCompensationInRem: number;
    rightCompensationInRem: number;
};

const props = defineProps<EditPopupProps>();
</script>
