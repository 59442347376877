<template>
    <div class="flex flex-col items-center" :class="{ 'z-50 drop-shadow-md': props.isChangeTypePopupOpen }">
        <div class="w-[0.1875rem] h-[0.3125rem]" :class="[backgroundColorClass]" />

        <div
            v-if="showPreviewIcon"
            class="absolute -top-3.5 flex place-content-center gap-1 rounded-sm p-0.5 drop-shadow-[0_0_0.0625rem_rgba(0,0,0,0.3)] motion-preset-pop motion-duration-300"
            :class="[backgroundColorClass, iconColorClass]"
        >
            <ReplaceIcon class="size-3" />
        </div>

        <div
            v-if="props.isChangeTypePopupOpen"
            ref="changeTypePopupRef"
            class="rounded-md border-2 p-2 connect-bar-inverted-border-radius-change-phase-button z-10 after:w-[1.125rem] relative"
            :class="[borderColor, backgroundColor, afterBackgroundColor, fillColor]"
            :style="floatingStyles"
        >
            <!-- Left inverted border radius -->
            <svg
                class="absolute -bottom-1.5 right-1/2 -translate-x-[0.5625rem]"
                viewBox="0 0 100 100"
                width="4"
                height="4"
            >
                <mask id="invertedBorderRadiusLeft">
                    <rect x="0" y="0" width="100" height="100" fill="white" />
                    <circle cx="0" cy="100" r="100" fill="black" />
                </mask>

                <rect x="0" y="0" width="100" height="100" mask="url(#invertedBorderRadiusLeft)" />
            </svg>

            <!-- Right inverted border radius -->
            <svg
                class="absolute -bottom-1.5 left-1/2 translate-x-[0.5625rem]"
                viewBox="0 0 100 100"
                width="4"
                height="4"
            >
                <mask id="invertedBorderRadiusRight">
                    <rect x="0" y="0" width="100" height="100" fill="white" />
                    <circle cx="100" cy="100" r="100" fill="black" />
                </mask>

                <rect x="0" y="0" width="100" height="100" mask="url(#invertedBorderRadiusRight)" />
            </svg>

            <!-- Filler left -->
            <svg class="absolute -bottom-1 right-1/2 -translate-x-[0.5rem]" viewBox="0 0 100 100" width="1" height="1">
                <rect x="0" y="0" width="100" height="100" />
            </svg>

            <!-- Filler right -->
            <svg class="absolute -bottom-1 left-1/2 translate-x-[0.5rem]" viewBox="0 0 100 100" width="1" height="1">
                <rect x="0" y="0" width="100" height="100" />
            </svg>

            <ChangeTypePopupContent
                :phase-type="props.phaseType"
                :specializations="props.specializations"
                :selected-specialization="props.selectedSpecialization"
                @change-specialization="(specialization) => emit('change-specialization', specialization)"
                @close-popup="
                    triggerHideTooltip();
                    $emit('toggle-change-phase-button');
                "
            />
        </div>

        <Tooltip placement="bottom" :is-teleported="true">
            <template #trigger="tooltipProps">
                <button
                    ref="changeTypeButtonRef"
                    :aria-labelledby="tooltipProps['aria-labelledby']"
                    type="button"
                    class="text-gray-700 size-[1.125rem] rounded-sm flex place-content-center place-items-center border focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-gray-700"
                    :class="[
                        colorClasses,
                        props.isChangeTypePopupOpen ? 'z-10' : 'drop-shadow-[0_0_0.0625rem_rgba(0,0,0,0.3)]',
                    ]"
                    @click="
                        triggerHideTooltip();
                        $emit('toggle-change-phase-button');
                    "
                    @mouseover="showPreviewIcon = true"
                    @mouseout="showPreviewIcon = false"
                    @focus="showPreviewIcon = true"
                    @blur="showPreviewIcon = false"
                >
                    <ReplaceIcon v-if="props.changePhaseStatus === 'Open-Popup'" class="size-3 pointer-events-none" />
                    <CloseIcon v-if="props.changePhaseStatus === 'Close-Popup'" class="size-3 pointer-events-none" />
                </button>
            </template>

            <template #text>
                <div v-if="props.isChangeTypePopupOpen" class="w-max">
                    {{ $t('close-change-phase-type-popup') }}
                </div>

                <div v-if="!props.isChangeTypePopupOpen" class="w-max">
                    {{ $t('open-change-phase-type-popup') }}
                </div>
            </template>
        </Tooltip>
    </div>
</template>

<script setup lang="ts">
import { autoUpdate, offset, useFloating } from '@floating-ui/vue';
import { computed, provide, ref } from 'vue';
import CloseIcon from '../../Icon/CloseIcon.vue';
import ReplaceIcon from '../../Icon/ReplaceIcon.vue';
import Tooltip from '../../Tooltip/Tooltip.vue';
import { triggerHideTooltipKey } from '../../Tooltip/injectionKeys';
import { PhaseType } from '../Timeline.types';
import { SpecializationType } from '../allocation/SpecializationMappings';
import {
    getActiveBackgroundColorClassForType,
    getActiveBorderColorClassForType,
    getBorderColorClassForType,
    getFillColorClassForType,
    getHoverStrongBackgroundColorClassForType,
    getHoverTextColorClassForType,
    getLightBackgroundColorClassForType,
    getStrongBackgroundColorAfterClassForType,
    getStrongBackgroundColorClassForType,
    getTextColorClassForType,
} from '../allocation/shared';
import ChangeTypePopupContent from './ChangeTypePopupContent.vue';

type ChangePhaseButtonProps = {
    phaseType: PhaseType;
    changePhaseStatus: 'Open-Popup' | 'Close-Popup' | 'Dont-Show';
    isChangeTypePopupOpen: boolean;
    specializations: (SpecializationType | null)[];
    selectedSpecialization: SpecializationType | null;
    hasTemporaryChangeMarker: boolean;
};

const props = defineProps<ChangePhaseButtonProps>();

const colorClasses = computed(() => {
    const backgroundColorClass = getLightBackgroundColorClassForType(props.phaseType);
    const textColorClass = getTextColorClassForType(props.phaseType);
    const hoverBackgroundColorClass = getHoverStrongBackgroundColorClassForType(props.phaseType);
    const hoverTextColorClass = getHoverTextColorClassForType(props.phaseType);
    const activeBackgroundColorClass = getActiveBackgroundColorClassForType(props.phaseType);
    const borderColorClass = getBorderColorClassForType(props.phaseType);
    const borderActiveColorClass = getActiveBorderColorClassForType(props.phaseType);

    return `
        ${backgroundColorClass} ${borderColorClass} ${textColorClass}
        ${hoverBackgroundColorClass} ${hoverTextColorClass}
        ${activeBackgroundColorClass} active:text-gray-50 ${borderActiveColorClass}
    `;
});

const backgroundColorClass = computed(() => getStrongBackgroundColorClassForType(props.phaseType));

const emit = defineEmits<{
    (e: 'toggle-change-phase-button'): void;
    (e: 'change-specialization', specialization: SpecializationType | null): void;
}>();

// This is used to force close the Tooltip after clicking the button
const triggerHideTooltipKeyCounter = ref(0);
provide(triggerHideTooltipKey, triggerHideTooltipKeyCounter);

function triggerHideTooltip() {
    triggerHideTooltipKeyCounter.value = triggerHideTooltipKeyCounter.value + 1;
}

const borderColor = computed(() => getBorderColorClassForType(props.phaseType));
const backgroundColor = computed(() => getLightBackgroundColorClassForType(props.phaseType));
const afterBackgroundColor = computed(() => getStrongBackgroundColorAfterClassForType(props.phaseType));
const fillColor = computed(() => getFillColorClassForType(props.phaseType));

const iconColorClass = computed(() => {
    if (props.phaseType === 'Durchführung' || props.phaseType === 'Projekt') {
        return 'text-gray-50';
    }

    return 'text-gray-700';
});

const changeTypeButtonRef = ref();
const changeTypePopupRef = ref();

const { floatingStyles } = useFloating(changeTypeButtonRef, changeTypePopupRef, {
    open: props.isChangeTypePopupOpen,
    placement: 'top',
    whileElementsMounted: autoUpdate,
    middleware: [offset(0)],
});

const showPreviewIcon = ref(false);
</script>

<style scoped>
.connect-bar-inverted-border-radius-change-phase-button {
    position: relative;
}

.connect-bar-inverted-border-radius-change-phase-button::after {
    content: '';
    position: absolute;
    height: 1px;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%);
}
</style>
