import { AllocationUsage, TimelineVariant } from '@/components/Timeline/allocation/types';
import {
    GetEventDataForTimelineDocument,
    TimelineAreaFragment,
    TimelineEventFragment,
    TimelineFullSiteFragment,
    TimelineRoomFragment,
} from '@/generated/graphql';
import { useQuery } from '@vue/apollo-composable';
import { computed, Ref, ref, toValue } from 'vue';

const EMPTY_SITE = {
    id: '',
    nameShort: '',
    buildings: {
        nodes: [],
    },
    zones: {
        nodes: [],
    },
};

export function useEventTimelineData(eventId: Ref<string>) {
    const variantsRef: Ref<TimelineVariant[]> = ref([]);
    const event: Ref<TimelineEventFragment | undefined> = ref();
    const rooms: Ref<TimelineRoomFragment[]> = ref([]);
    const areas: Ref<TimelineAreaFragment[]> = ref([]);
    const site: Ref<TimelineFullSiteFragment> = ref(EMPTY_SITE);
    const usages: Ref<AllocationUsage[]> = ref([]);

    const { loading, error, onResult } = useQuery(
        GetEventDataForTimelineDocument,
        computed(() => ({ eventId: toValue(eventId) })),
    );

    onResult((result) => {
        if (result.error) {
            throw new Error('Error on receiving event timeline data');
        }
        const data = result.data;
        if (data) {
            event.value = data.event ?? undefined;
            const variants = data.event?.variants.nodes;
            if (variants) {
                variantsRef.value = variants;
            }
            rooms.value = data.rooms?.nodes ?? [];
            areas.value = data.areas?.nodes ?? [];
            const sites = data.sites?.nodes ?? [];
            if (!sites) throw new Error(`Missing site for event: ${toValue(eventId)}`);
            site.value = sites[0];
            usages.value = data.usages?.nodes ?? [];
        }
    });

    function setVariantsRef(value: TimelineVariant[]) {
        variantsRef.value = value;
    }

    return { variantsRef, setVariantsRef, loading, error, onResult, site, event, rooms, areas, usages };
}
