<template>
    <div class="group flex flex-col items-center -z-20">
        <div class="w-px" :class="[interactiveClasses]" :style="barHeightStyle" />

        <Tooltip placement="bottom" :is-teleported="true">
            <template #trigger="tooltipProps">
                <button
                    type="button"
                    class="flex rounded-sm place-content-center items-center focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-gray-700"
                    :class="[interactiveClasses, isDisabled ? 'cursor-not-allowed' : 'cursor-pointer']"
                    :aria-disabled="isDisabled"
                    v-bind="tooltipProps"
                    @click="clickInsertPhase"
                >
                    <PlusIcon :class="insertPhaseButtonIconSizeClass" />
                </button>
            </template>

            <template #text>
                <div v-if="isDisabled" class="min-w-max">{{ $t('inserting-phase-not-possible') }}</div>
                <div v-else class="min-w-max flex flex-col">
                    <i18n path="insert-phase-with-name">
                        <template #phaseName>
                            <span class="font-semibold">
                                {{ props.phaseName }}
                            </span>
                        </template>
                    </i18n>

                    <span class="text-xs text-gray-500">
                        {{ $t('insert-phase-with-name-hint') }}
                    </span>
                </div>
            </template>
        </Tooltip>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import PlusIcon from '../../Icon/PlusIcon.vue';
import Tooltip from '../../Tooltip/Tooltip.vue';
import { allocationHeightInRem, insertPhaseBarHeightInRem, insertPhaseButtonIconSizeClass } from '../sharedStyles';

type InsertPhaseButtonProps = {
    barHeight: 'Small' | 'Full';
    phaseName: string | null; // string === Name of phase, null === Button is disabled
};

const props = defineProps<InsertPhaseButtonProps>();

const isDisabled = computed(() => !props.phaseName);

const emit = defineEmits<{
    (e: 'insertPhase'): void;
}>();

const interactiveClasses = computed(() => {
    if (isDisabled.value) {
        return `
            bg-gray-400 text-gray-300
        `;
    }

    return `
        bg-gray-600 text-gray-50
        group-hover:bg-gray-700 group-hover:text-gray-50
        group-active:border-gray-900 group-active:bg-gray-900 group-active:text-gray-50
    `;
});

function clickInsertPhase(event: Event) {
    if (isDisabled.value) {
        event.preventDefault();
        event.stopPropagation();
    } else {
        emit('insertPhase');
    }
}

const barHeightStyle = computed(() => {
    if (props.barHeight === 'Full') {
        return `height: ${insertPhaseBarHeightInRem + allocationHeightInRem / 2}rem;`;
    } else {
        return `height: ${insertPhaseBarHeightInRem}rem;`;
    }
});
</script>
