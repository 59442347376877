import {
    MAXIMAL_YEAR,
    MINIMAL_YEAR,
} from '@/components/TimelineIntervalChanger/composables/useTimelineIntervalChanger';
import { DateTime, QuarterNumbers } from 'luxon';
import { computed, ComputedRef, Ref, ref, toValue, watchEffect } from 'vue';
import { MonthButtonStatus } from '../Timeline.vue';

type UseTimelineRangeReturn = {
    startDateTime: ComputedRef<DateTime>;
    endDateTime: ComputedRef<DateTime>;
    isTimeSpanModified: ComputedRef<boolean>;
    quarter: ComputedRef<QuarterNumbers>;
    year: ComputedRef<number>;
    previousMonthButton: Ref<MonthButtonStatus>;
    nextMonthButton: Ref<MonthButtonStatus>;
    onChangeQuarter: (newQuarter: QuarterNumbers) => void;
    onChangeYear: (newYear: number) => void;
    onClickPreviousQuarter: () => void;
    onClickNextQuarter: () => void;
    onExpandToPreviousMonth: () => void;
    onExpandToNextMonth: () => void;
};

export function sortByDateAsc(a: string, b: string) {
    const startA = DateTime.fromISO(a);
    const startB = DateTime.fromISO(b);
    return startA < startB ? -1 : startA > startB ? 1 : 0;
}

export function useTimelineRange(start: Ref<DateTime>): UseTimelineRangeReturn {
    // DateTime selected in the TimelineIntervalChanger
    const selectedStartDateTime: Ref<DateTime> = ref(toValue(start));
    // Timeline allows to expand the displayed time range by quarters
    const monthsBefore = ref(0); // Quarters before selected start DateTime
    const monthsAfter = ref(0); // Quarters after selected end DateTime

    watchEffect(() => {
        selectedStartDateTime.value = toValue(start);
    });

    function onChangeQuarter(newQuarter: QuarterNumbers) {
        const quarterDifference = newQuarter - quarter.value;
        selectedStartDateTime.value = selectedStartDateTime.value.plus({ quarters: quarterDifference });
        monthsBefore.value = 0;
        monthsAfter.value = 0;
    }

    function onChangeYear(newYear: number) {
        const yearDifference = newYear - year.value;
        selectedStartDateTime.value = selectedStartDateTime.value.plus({ years: yearDifference });
        monthsBefore.value = 0;
        monthsAfter.value = 0;
    }

    function onClickPreviousQuarter() {
        selectedStartDateTime.value = selectedStartDateTime.value.minus({ quarters: 1 });
        monthsBefore.value = 0;
        monthsAfter.value = 0;
    }

    function onClickNextQuarter() {
        selectedStartDateTime.value = selectedStartDateTime.value.plus({ quarters: 1 });
        monthsBefore.value = 0;
        monthsAfter.value = 0;
    }

    function onExpandToPreviousMonth() {
        monthsBefore.value = toValue(monthsBefore) + 1;
    }

    function onExpandToNextMonth() {
        monthsAfter.value = toValue(monthsAfter) + 1;
    }

    // DateTimes the Timeline actually shows
    const displayedStartDateTime = computed(() => {
        return selectedStartDateTime.value.startOf('quarter').startOf('day').minus({ months: monthsBefore.value });
    });
    const displayedEndDateTime = computed(() => {
        return selectedStartDateTime.value.endOf('quarter').startOf('day').plus({ months: monthsAfter.value });
    });
    const isTimeSpanModified = computed(() => {
        return monthsBefore.value !== 0 || monthsAfter.value !== 0;
    });
    const quarter = computed(() => {
        return selectedStartDateTime.value.quarter;
    });
    const year = computed(() => {
        return selectedStartDateTime.value.year;
    });

    const previousMonthButton = computed(() => {
        const isDisabled =
            displayedStartDateTime.value.year === MINIMAL_YEAR && displayedStartDateTime.value.month === 1;

        return isDisabled ? 'Disabled' : 'Visible';
    });
    const nextMonthButton = computed(() => {
        const isDisabled =
            displayedStartDateTime.value.year === MAXIMAL_YEAR && displayedStartDateTime.value.month === 12;

        return isDisabled ? 'Disabled' : 'Visible';
    });

    return {
        startDateTime: displayedStartDateTime,
        endDateTime: displayedEndDateTime,
        isTimeSpanModified: isTimeSpanModified,
        quarter,
        year,
        previousMonthButton,
        nextMonthButton,
        onChangeQuarter,
        onChangeYear,
        onClickPreviousQuarter,
        onClickNextQuarter,
        onExpandToPreviousMonth,
        onExpandToNextMonth,
    };
}
