import { removeObjectFromCache } from '@/utils/apollo';
import { UseMutationReturn, useApolloClient, useQuery, useQueryLoading } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { Ref, computed, unref, watch } from 'vue';
import { RouteLocationRaw, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { GetLeefLocationsDocument, LocationSearchDocument } from '../generated/graphql';
import { $t } from '../plugins/fluent';
import { useResult } from '../utils/graphql';
import { Form, FormField, FormFieldGroup } from './forms/types';
import { LocationType } from './locationTypes';

export const locationTypesPlural = ['Sites', 'Buildings', 'Levels', 'Rooms', 'Zones', 'Areas', 'Plots'];
export type LocationTypePlural = (typeof locationTypesPlural)[number];

export type LocationTypeWithSubtypes = {
    type: LocationType;
    subTypes: LocationSubType[];
};

export type LocationTypeWithSubtype = {
    type: LocationType;
    subType: LocationSubType;
};

export type LocationSubType = {
    id: string;
    name: string;
};

export type Location = {
    __typename: LocationType;
    id: string;
    nameShort: string;
    nameLong: string;
};

export type LocationList = { [Property in LocationType]: Location[] };

export type QueryKeyValue = {
    key: string;
    value: string;
};

export function parentType(type: LocationType | undefined): LocationType | undefined {
    switch (type) {
        case 'Site':
            return undefined;
        case 'Building':
            return 'Site';
        case 'Level':
            return 'Building';
        case 'Room':
            return 'Level';
        case 'Zone':
            return 'Site';
        case 'Area':
            return 'Zone';
        case 'Plot':
            return 'Area';
        case 'RoomPartition':
            return 'Room';
        case undefined:
            return undefined;
    }
}

export function prettyPrintType(type: LocationType, count?: number): string {
    return $t('area-type', { type, count: count ?? 1 });
}

export function prettyPrintDescription(type: LocationType): string {
    return $t('area-type-description', { type: type });
}

export function iconForType(type: LocationType | undefined): string {
    switch (type) {
        case 'Site':
            return 'area_chart';
        case 'Building':
            return 'home';
        case 'Level':
            return 'layers';
        case 'Room':
            return 'meeting_room';
        case 'Zone':
            return 'nature';
        case 'Area':
            return 'calendar_view_month';
        case 'Plot':
            return 'deck';
        case 'RoomPartition':
            return 'tab_unselected';
        default:
            return ''; // TODO return proper default icon
    }
}

export type LocationSearchResult = {
    to: RouteLocationRaw;
    icon: string;
    label: string;
    subtype: string | undefined;
};

type LocationSearchQueryResult = {
    __typename?: LocationType;
    id: string;
    nameShort: string;
    type?: {
        name: string;
    } | null;
};

function toSearchResult(obj: LocationSearchQueryResult) {
    return {
        id: obj.id,
        key: `${obj.__typename}_${obj.id}`.toLowerCase(),
        label: obj.nameShort,
        to: routeForLocation(obj.__typename, obj.id),
        icon: iconForType(obj.__typename),
        subtype: obj.type?.name,
    };
}

export function useLocationSearch(search: Ref<string>) {
    const searchQuery = useQuery(
        LocationSearchDocument,
        computed(() => ({ search: unref(search) })),
    );
    const loading = useQueryLoading();

    const items = computed(() => {
        const res = searchQuery.result.value;
        const sites = res?.sites?.nodes.map((o) => toSearchResult(o)) || [];
        const buildings = res?.buildings?.nodes.map((o) => toSearchResult(o)) || [];
        const levels = res?.levels?.nodes.map((o) => toSearchResult(o)) || [];
        const rooms = res?.rooms?.nodes.map((o) => toSearchResult(o)) || [];
        const areas = res?.areas?.nodes.map((o) => toSearchResult(o)) || [];
        const zones = res?.zones?.nodes.map((o) => toSearchResult(o)) || [];
        const plots = res?.plots?.nodes.map((o) => toSearchResult(o)) || [];
        const roomPartitions = res?.roomPartitions?.nodes.map((o) => toSearchResult(o)) || [];

        return [
            { id: 'sites', label: `${prettyPrintType('Site')} (${sites.length})`, children: [...sites] },
            {
                id: 'buildings',
                label: `${prettyPrintType('Building', 2)} (${buildings.length})`,
                children: [...buildings],
            },
            { id: 'levels', label: `${prettyPrintType('Level', 2)} (${levels.length})`, children: [...levels] },
            { id: 'rooms', label: `${prettyPrintType('Room', 2)} (${rooms.length})`, children: [...rooms] },
            { id: 'areas', label: `${prettyPrintType('Area', 2)} (${areas.length})`, children: [...areas] },
            { id: 'zones', label: `${prettyPrintType('Zone', 2)} (${zones.length})`, children: [...zones] },
            { id: 'plots', label: `${prettyPrintType('Plot', 2)} (${plots.length})`, children: [...plots] },
            {
                id: 'roompartitions',
                label: `${prettyPrintType('RoomPartition', 2)} (${roomPartitions.length})`,
                children: [...roomPartitions],
            },
        ].filter((x) => x.children.length);
    });

    return {
        loading,
        items,
    };
}

export function routeForLocation(type: LocationType | undefined, id: string): RouteLocationRaw {
    if (id === '') {
        return { name: 'locations' };
    }
    switch (type) {
        case 'Site':
            return { name: 'site-details', params: { siteId: id } };
        case 'Building':
            return { name: 'building-details', params: { buildingId: id } };
        case 'Level':
            return { name: 'level-details', params: { levelId: id } };
        case 'Room':
            return { name: 'room-details', params: { roomId: id } };
        case 'Zone':
            return { name: 'zone-details', params: { zoneId: id } };
        case 'Area':
            return { name: 'area-details', params: { areaId: id } };
        case 'Plot':
            return { name: 'plot-details', params: { plotId: id } };
        case 'RoomPartition':
            return { name: 'room-partition-detail', params: { partitionId: id } };
        default:
            return {};
    }
}

export function useLeefLocations(locations: Ref<Location[]>): Ref<Map<string, Location[]>> {
    const args = computed(() => {
        function _filter(type: LocationType) {
            return locations.value.filter((x) => x.__typename === type).map((x) => x.id);
        }

        return {
            siteIds: _filter('Site'),
            buildingIds: _filter('Building'),
            levelIds: _filter('Level'),
            zoneIds: _filter('Zone'),
            areaIds: _filter('Area'),
        };
    });

    const query = useQuery(GetLeefLocationsDocument, args);

    watch(args, (x) => query.refetch(x));
    const queryRes = useResult(query.result, undefined, (x) => x);

    return computed(() => {
        const val = queryRes.value;
        const res = new Map<string, Location[]>();
        if (!val) return res;

        // add rooms an plots we already know
        locations.value.filter((x) => x.__typename === 'Room').forEach((x) => res.set(uniqueLocationId(x), [x]));
        locations.value.filter((x) => x.__typename === 'Plot').forEach((x) => res.set(uniqueLocationId(x), [x]));

        // add other values from server
        val.sites.nodes.forEach((x) =>
            res.set(uniqueLocationId(x), [
                ...x.buildings.nodes.flatMap((b) => b.levels.nodes.flatMap((l) => l.rooms.nodes)),
                ...x.zones.nodes.flatMap((b) => b.areas.nodes.flatMap((l) => l.plots.nodes)),
            ]),
        );
        val.buildings.nodes.forEach((x) =>
            res.set(
                uniqueLocationId(x),
                x.levels.nodes.flatMap((l) => l.rooms.nodes),
            ),
        );
        val.levels.nodes.forEach((x) => res.set(uniqueLocationId(x), x.rooms.nodes));
        val.zones.nodes.forEach((x) =>
            res.set(
                uniqueLocationId(x),
                x.areas.nodes.flatMap((a) => a.plots.nodes),
            ),
        );
        val.areas.nodes.forEach((x) => res.set(uniqueLocationId(x), x.plots.nodes));

        return res;
    });
}

export function uniqueLocationId(loc: Location) {
    return `${loc.__typename}_${loc.id}`;
}

export function flattenLocationList(list: LocationList) {
    return [
        ...list.Site,
        ...list.Building,
        ...list.Level,
        ...list.Room,
        ...list.Zone,
        ...list.Area,
        ...list.Plot,
        ...list.RoomPartition,
    ];
}

export function useGetLocation(locationId: Ref<string>, queryDocument: DocumentNode) {
    const getLocationQuery = useQuery(
        queryDocument,
        computed(() => ({ id: unref(locationId) })),
    );

    const location = useResult(getLocationQuery.result, undefined, (data) => data.level);
    return { location, getLocationQuery };
}

type DeleteLocation = {
    __typename: string;
    id: string;
    nameShort: string;
};

export function useDeleteLocation(
    location: Ref<DeleteLocation>,
    deleteMutation: UseMutationReturn<any, any>,
    routeToParent: Ref<RouteLocationRaw>,
) {
    const fluent = useFluent();
    const toast = useToast();
    const router = useRouter();

    const type = computed(() => location.value.__typename.toLowerCase());

    return async () => {
        if (!confirm(fluent.$t('confirm-delete-object', { type: type.value, name: location.value.nameShort }))) return;
        const res = await deleteMutation.mutate(
            { id: location.value.id },
            { update: (cache: any) => removeObjectFromCache(cache, location.value.id, location.value.__typename) },
        );

        const deleteKey = computed(() => `delete${location.value.__typename}`);
        const resultKey = computed(() => {
            if (location.value.__typename === 'RoomPartition') {
                return 'roomPartition';
            }
            return type.value;
        });

        const { id, nameShort } = res?.data[deleteKey.value][resultKey.value] ?? {};
        if (id && nameShort) {
            toast.success(fluent.$t('delete-success-notification', { type: type.value, name: nameShort }));
            router.replace(routeToParent.value);
        }
    };
}

export function _gqlTypeForField(field: FormField, required: boolean = false): string {
    switch (field.type) {
        case 'checkbox':
            return required ? 'Boolean!' : 'Boolean';
        case 'float':
            return required ? 'BigFloat!' : 'BigFloat';
        case 'number':
            return required ? 'Int!' : 'Int';
        case 'string':
        case 'select':
            // fixme: maybe configure this in the attribute-definition?
            // Or use ID for select?
            if (field.dbName.endsWith('Id')) return 'UUID!';
            return required ? 'String!' : 'String';
    }
}

export const lowerCaseFirstLetter = (name: string) => {
    const first = name.charAt(0).toLowerCase();
    const remaining = name.substring(1);
    return first + remaining;
};

export const upperCaseFirstLetter = (name: string) => {
    const first = name.charAt(0).toUpperCase();
    const remaining = name.substring(1);
    return first + remaining;
};

const pluralizedKey = (typename: string, dbName: string) => {
    const base = `${lowerCaseFirstLetter(typename)}${upperCaseFirstLetter(dbName)}`;
    let pluralized = ``;
    if (base.endsWith('s')) {
        pluralized = base;
    } else if (base.endsWith('x')) {
        pluralized = `${base}es`;
    } else {
        pluralized = `${base}s`;
    }
    return `${pluralized}UsingId`;
};

export const useUpdateLocation = (locationId: Ref<string>, locationForm: Form, typename: LocationType) => {
    const router = useRouter();
    const toast = useToast();
    const fluent = useFluent();
    const client = useApolloClient().client;

    // FIXME on update of type, the location object exists in both type categories
    // in the treeview. If there is no short solution to this, we have to wait until
    // the treeview is refactored/replaced by better navigation

    const generateUpdateMutation = () => {
        let vars = `$id: UUID!\n`;
        let patchValues = ``;
        const variables: { [key: string]: string | number | boolean | undefined } = { id: locationId.value };

        locationForm.forEach((g: FormFieldGroup) => {
            g.fields.forEach((f: FormField) => {
                if (!f.readonly) {
                    if (f.isTimeVariant) {
                        const tvKey = pluralizedKey(typename, f.dbName);
                        if (f.timeVariantId) {
                            // update existing timevariant
                            vars = vars + `$${f.dbName}Id: UUID!\n`;
                            vars = vars + `$${f.dbName}Value: ${_gqlTypeForField(f)}\n`;
                            patchValues =
                                patchValues +
                                ` ${tvKey}: { updateById: { patch: { value: $${f.dbName}Value }, id: $${f.dbName}Id }}`;
                            variables[`${f.dbName}Id`] = f.timeVariantId;
                            variables[`${f.dbName}Value`] = f.value;
                        } else if (f.value !== '' && f.value !== undefined) {
                            // create new timevariant
                            vars = vars + `$${f.dbName}Value: ${_gqlTypeForField(f)}!\n`;
                            patchValues = patchValues + ` ${tvKey}: { create: { value: $${f.dbName}Value}}`;
                            variables[`${f.dbName}Value`] = f.value;
                        }
                    } else {
                        vars = vars + `$${f.dbName}: ${_gqlTypeForField(f)}\n`;
                        patchValues = patchValues + ` ${f.dbName}: $${f.dbName}`;
                        variables[f.dbName] = f.value;
                    }
                }
            });
        });
        const mutation = `mutation Update${typename}(${vars}){
                update${typename}(
                    input: {
                        id: $id
                        patch: {
                            ${patchValues}
                        }
                    }
                ) {
                    ${lowerCaseFirstLetter(typename)} {
                        id
                        nameShort
                    }
                }
            }`;

        return {
            mutation,
            variables,
        };
    };

    const save = async () => {
        const { mutation, variables } = generateUpdateMutation();
        const res = await client.mutate({
            mutation: gql(mutation),
            variables: variables,
            errorPolicy: 'all',
        });

        if (res?.errors) {
            throw new Error(`Error on location update: ${res.errors}`);
        }

        const updateKey = computed(() => `update${typename}`);
        const resultKey = computed(() => `${lowerCaseFirstLetter(typename)}`);

        const { id, nameShort } = res?.data[updateKey.value][resultKey.value] ?? {};
        if (id && nameShort) {
            const to = routeForLocation(typename, locationId.value);
            toast.success(fluent.$t('update-success-notification', { type: typename.toLowerCase(), name: nameShort }), {
                onClick: () => router.push(to),
            });
            router.push(to);
        }
    };

    return {
        save,
    };
};

export const useCreateLocation = (locationForm: Form, typename: LocationType, parent?: QueryKeyValue) => {
    const router = useRouter();
    const toast = useToast();
    const fluent = useFluent();
    const client = useApolloClient().client;

    const generateCreateMutation = () => {
        let vars = parent ? `$${parent.key}: UUID!` : '';
        let patchValues = parent ? `${parent.key}: $${parent.key}` : '';
        const variables: { [key: string]: string | number | boolean | undefined } = {};
        if (parent) variables[parent.key] = parent.value;

        locationForm.forEach((g: FormFieldGroup) => {
            g.fields.forEach((f: FormField) => {
                if (!f.readonly) {
                    if (f.isTimeVariant) {
                        if (f.value !== '' && f.value !== undefined) {
                            vars = vars + ` $${f.dbName}Value: ${_gqlTypeForField(f)}!\n`;
                            const tvKey = pluralizedKey(typename, f.dbName);
                            patchValues = patchValues + ` ${tvKey}: { create: { value: $${f.dbName}Value }}`;
                            variables[`${f.dbName}Id`] = f.timeVariantId;
                            variables[`${f.dbName}Value`] = f.value;
                        }
                    } else {
                        let required = false;
                        if (f.dbName === 'nameShort') {
                            required = true;
                        }
                        vars = vars + ` $${f.dbName}: ${_gqlTypeForField(f, required)}\n`;
                        patchValues = patchValues + ` ${f.dbName}: $${f.dbName}`;
                        variables[f.dbName] = f.value;
                    }
                }
            });
        });
        const mutation = `mutation Create${typename}(${vars}){
                create${typename}(
                    input: {
                        ${lowerCaseFirstLetter(typename)}: {
                            ${patchValues}
                        }
                    }
                ) {
                    ${lowerCaseFirstLetter(typename)} {
                        id
                        nameShort
                    }
                }
            }`;

        return {
            mutation,
            variables,
        };
    };

    const save = async () => {
        const { mutation, variables } = generateCreateMutation();
        const res = await client.mutate({
            mutation: gql(mutation),
            variables: variables,
            errorPolicy: 'all',
        });

        if (res?.errors) {
            throw new Error(`Error on location create: ${res.errors}`);
        }

        const updateKey = computed(() => `create${typename}`);
        const resultKey = computed(() => `${lowerCaseFirstLetter(typename)}`);

        const { id, nameShort } = res?.data[updateKey.value][resultKey.value] ?? {};
        if (id && nameShort) {
            const to = routeForLocation(typename, id);
            toast.success(fluent.$t('create-success-notification', { type: typename.toLowerCase(), name: nameShort }), {
                onClick: () => router.push(to),
            });
            router.push(to);
        }
    };

    return {
        save,
    };
};

export const getFormField = (locationForm: Form, id: string): FormField | undefined => {
    let field = undefined;
    locationForm.forEach((g: FormFieldGroup) => {
        g.fields.forEach((f) => {
            if (f.dbName === id) {
                field = f;
            }
        });
    });
    return field;
};

const KEY_ISSUANCE_CHOICES = [
    { id: '1', name: 'Besucher-Services' },
    { id: '2', name: 'Empfang Messehochhaus 1' },
    { id: '3', name: 'Empfang Messehochhaus 2' },
    { id: '4', name: 'Event and Event Design' },
    { id: '5', name: 'Gebäudeautomation' },
    { id: '6', name: 'Geländesicherheit' },
    { id: '7', name: 'MarCom -> ZBL-Assistenz' },
    { id: '8', name: 'Messewache Nord' },
    { id: '9', name: 'Messewache Ost' },
    { id: '10', name: 'Noch nicht definiert' },
    { id: '11', name: 'KC | Veranstaltungsservice' },
    { id: '21', name: 'Protokoll/VIP Services' },
];

export const CONTACT_BOOKING_CHOICES = [
    { id: '1', name: 'Bau/Km3.0' },
    { id: '2', name: 'Besucher-Services' },
    { id: '3', name: 'Betriebstechnik/Werkstatt' },
    { id: '4', name: 'Event and Event Design' },
    { id: '5', name: 'Gastronomie-Services' },
    { id: '6', name: 'Gebäudeautomation' },
    { id: '7', name: 'Geländesicherheit' },
    { id: '8', name: 'Health, Safety, Environment und Brandschutz' },
    { id: '9', name: 'Kapazitätsmanagement' },
    { id: '10', name: 'KC | Kongresse/Tagungen/Events/CC/Confex' },
    { id: '11', name: 'KC | Leiter:in' },
    { id: '12', name: 'MarCom | ZBL-Assistenz' },
    { id: '13', name: 'Noch nicht definiert' },
    { id: '14', name: 'Techn. Controlling /Mietmanagement' },
    { id: '15', name: 'Technische Dienstleisterkoordination' },
    { id: '16', name: 'Technische Gebäudeausrüstung (TGA)' },
    { id: '17', name: 'Veranstaltungstechnik und -genehmigung' },
    { id: '18', name: 'Verkehr/Logistik' },
    { id: '19', name: 'ZB IT | Service-Management' },
    { id: '20', name: 'KC | Veranstaltungsservice' },
    { id: '21', name: 'Protokoll/VIP Services' },
];

export const getKeyIssuanceChoices = () => {
    return KEY_ISSUANCE_CHOICES.sort((a, b) => a.name.localeCompare(b.name));
};

export const getContactBookingChoices = () => {
    return CONTACT_BOOKING_CHOICES.sort((a, b) => a.name.localeCompare(b.name));
};
