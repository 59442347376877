import { ref } from 'vue';

export function useTimelineOptionsSwitch() {
    const activeLocationsDisplayIndex = ref(0);
    const showSections = ref(true);

    function setActiveLocationsDisplayIndex(value: number) {
        activeLocationsDisplayIndex.value = value;
        showSections.value = value === 0;
    }

    return {
        activeLocationsDisplayIndex,
        showSections,
        setActiveLocationsDisplayIndex,
    };
}
