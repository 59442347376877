<template>
    <div class="flex flex-col items-center">
        <Tooltip>
            <template #trigger="tooltipProps">
                <div
                    class="px-1 text-center text-gray-50 h-[1.125rem] leading-[1.125rem] text-xs overflow-hidden text-clip rounded-sm drop-shadow-[0_0_0.0625rem_rgba(0,0,0,0.3)]"
                    :class="[backgroundColorClass, textColorClass]"
                    :style="widthStyle"
                    :aria-labelledby="tooltipProps['aria-labelledby']"
                    tabindex="0"
                >
                    <template v-if="!displayLongLabel">
                        {{ $t('column-counter-short', { count: props.count }) }}
                    </template>

                    <template v-if="displayLongLabel">
                        {{ $t('column-counter-long', { count: props.count }) }}
                    </template>
                </div>
            </template>

            <template #text>
                <div class="text-center w-full">
                    {{ $t('column-counter-long', { count: props.count }) }}
                </div>
            </template>

            <template #header>
                {{ phaseName }}
            </template>
        </Tooltip>

        <div class="w-[0.1875rem] h-[0.3125rem]" :class="backgroundColorClass" />
    </div>
</template>

<script setup lang="ts">
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import { computed } from 'vue';
import { PhaseType } from '../Timeline.types';
import { getStrongBackgroundColorClassForType, getTextColorClassForType } from '../allocation/shared';
import { columnWidthInRem } from '../sharedStyles';

type ColumnCounterProps = {
    count: number;
    phaseType: PhaseType;
    displayDoubleWidth: boolean;
    displayLongLabel: boolean;
};

const props = defineProps<ColumnCounterProps>();

const backgroundColorClass = computed(() => getStrongBackgroundColorClassForType(props.phaseType));
const textColorClass = computed(() => getTextColorClassForType(props.phaseType));

const widthStyle = computed(() => {
    if (props.displayDoubleWidth) {
        return { width: `${columnWidthInRem * 2}rem` };
    } else {
        return { width: `${columnWidthInRem}rem` };
    }
});
const phaseName = computed(() => {
    // TODO Map the phase type to the display names once the phase type is no longer a human-readable German name
    return props.phaseType;
});
</script>
