import { ActiveCard } from '@/components/Card/types';
import { CommentChangesStatus, FeedCommentType } from '@/components/Feed/types';
import { REQUIRED_STRING } from '@/components/Form/schema';
import { EventVariantStatus } from '@/event/types';
import {
    GetEventVariantCommentsDocument,
    GetProjectVariantCommentsDocument,
    MyUserFragment,
    SearchExistingEventVariantDocument,
    SearchExistingProjectVariantDocument,
    UpdateEventVariantAndCommentsDocument,
    UpdateProjectVariantAndCommentsDocument,
} from '@/generated/graphql';
import { ProjectVariantStatus } from '@/project/types';
import { useApolloClient, useMutation } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { computed, ref, Ref, toValue, watch } from 'vue';
import {
    VariantCardVariant,
    VariantCommentCreate,
    VariantCommentDelete,
    VariantCommentUpdate,
    VariantType,
} from '../types';

export function useVariantCardForm(
    objectId: Ref<string>,
    currentUser: MyUserFragment,
    variantRef: Ref<VariantCardVariant>,
    variantType: VariantType,
    variantsRef: Ref<VariantCardVariant[]>,
    setVariantsRef: (variants: VariantCardVariant[]) => void,
    activeCardRef: Ref<ActiveCard>,
    setActiveCard: (value: ActiveCard) => void,
) {
    const fluent = useFluent();
    const client = useApolloClient().client;
    const variantFormData = ref({
        name: variantRef.value.name,
        status: variantRef.value.status,
    });
    const variantFormErrors: Ref<{ name: string[] }> = ref({
        name: [],
    });
    const newCommentText = ref('');
    const commentsToCreate: Ref<VariantCommentCreate[]> = ref([]);
    const commentsToDelete: Ref<VariantCommentDelete[]> = ref([]);
    const commentsToUpdate: Ref<VariantCommentUpdate[]> = ref([]);

    const mutations = {
        Event: useMutation(UpdateEventVariantAndCommentsDocument),
        Project: useMutation(UpdateProjectVariantAndCommentsDocument),
    };
    const queryDocument = {
        Event: SearchExistingEventVariantDocument,
        Project: SearchExistingProjectVariantDocument,
    };

    const getCommentsDocument = {
        Event: GetEventVariantCommentsDocument,
        Project: GetProjectVariantCommentsDocument,
    };

    function setNewCommentText(text: string) {
        newCommentText.value = text;
    }

    function setCommentsToCreate(comments: VariantCommentCreate[]) {
        commentsToCreate.value = comments;
    }

    function setCommentsToDelete(comments: VariantCommentDelete[]) {
        commentsToDelete.value = comments;
    }

    const formChanged = computed(() => {
        if (
            commentsToCreate.value.length > 0 ||
            commentsToDelete.value.length > 0 ||
            commentsToUpdate.value.length > 0 ||
            variantFormData.value.name !== toValue(variantRef).name ||
            newCommentText.value !== ''
        ) {
            return true;
        }
        return false;
    });

    const saveButtonDisabledExplanation = computed(() => {
        if (!formChanged.value) return fluent.$t('no-form-changes-to-save');
        if (variantFormErrors.value.name.length > 0) return fluent.$t('unresolved-form-errors');
        if (newCommentText.value !== '') return fluent.$t('variant-unsaved-comment');
        return '';
    });

    watch(formChanged, (changed) => {
        const activeCard = toValue(activeCardRef);
        if (changed && activeCard.status !== 'View' && activeCard.id === toValue(variantRef).id) {
            setActiveCard({ ...activeCard, status: 'Edit-Touched' });
        } else {
            setActiveCard({ ...activeCard, status: 'Edit-Untouched' });
        }
    });

    async function resetVariantForm(resetUnsavedChanges: boolean) {
        variantFormData.value = { name: toValue(variantRef).name, status: toValue(variantRef).status };
        variantFormErrors.value = { name: [] };
        if (resetUnsavedChanges) {
            const getComments = getCommentsDocument[variantType];
            const result = await client.query({
                query: getComments,
                variables: { variantId: toValue(variantRef).id },
            });
            const comments = result.data.variant?.comments.nodes;
            if (comments) {
                setVariantsRef(
                    toValue(variantsRef).map((variant) =>
                        variant.id === toValue(variantRef).id
                            ? {
                                  ...variant,
                                  comments: {
                                      nodes: comments.map((comment) => ({ ...comment, hasUnsavedChanges: 'None' })),
                                  },
                              }
                            : variant,
                    ),
                );
            }
            commentsToCreate.value = [];
            commentsToUpdate.value = [];
            commentsToDelete.value = [];
            newCommentText.value = '';
        }
    }

    async function checkForExistingName(name: string) {
        const getVariantQueryDocument = queryDocument[variantType];
        const result = await client.query({
            query: getVariantQueryDocument,
            variables: { name: name.trim(), objectId: toValue(objectId) },
        });
        if (result.errors) throw new Error('Failed to check for existing variant name');

        if (result.data.variants?.nodes && result.data.variants.nodes.length > 0) {
            variantFormErrors.value = {
                ...variantFormErrors,
                name: [fluent.$t('event-variant-name-already-exists')],
            };
            return true;
        }
        return false;
    }

    async function handleNameFieldChange(value: string) {
        return await checkForExistingName(value);
    }

    async function handleNameFieldInput(value: string) {
        variantFormErrors.value = { ...variantFormErrors, name: [] };
        let newName = value;
        const parse = REQUIRED_STRING.safeParse(value);
        if (!parse.success) {
            const errors = parse.error.format()._errors ?? [];
            variantFormErrors.value = { ...variantFormErrors, name: errors };
        } else {
            newName = parse.data;
        }
        variantFormData.value = { ...variantFormData.value, name: newName };
    }

    function handleAddDateCommentEntryInput(comment: string) {
        newCommentText.value = comment;
    }

    async function onSave() {
        const { name, status } = variantFormData.value;
        const id = toValue(variantRef).id;

        if (name !== toValue(variantRef).name) {
            if ((await checkForExistingName(name)) === true) return;
        }

        const updateMutation = mutations[variantType];

        const result = await updateMutation.mutate({
            id,
            name: name,
            status: status,
            commentsToCreate: commentsToCreate.value,
            commentsToUpdate: commentsToUpdate.value.map((comment) => ({ id: comment.id, patch: comment })),
            commentsToDelete: commentsToDelete.value,
        });

        if (result?.errors) throw new Error('Could not save variant and comments');

        const updatedVariant = result?.data?.update?.variant;

        if (updatedVariant) {
            switch (variantType) {
                case 'Event':
                    setVariantsRef(
                        toValue(variantsRef).map((variant) =>
                            variant.id === id
                                ? {
                                      ...updatedVariant,
                                      status: updatedVariant.status as EventVariantStatus,
                                      comments: {
                                          nodes: updatedVariant.comments.nodes.map((comment) => ({
                                              ...comment,
                                              hasUnsavedChanges: 'None',
                                          })),
                                      },
                                  }
                                : variant,
                        ),
                    );
                    break;
                case 'Project':
                    setVariantsRef(
                        toValue(variantsRef).map((variant) =>
                            variant.id === id
                                ? {
                                      ...updatedVariant,
                                      status: updatedVariant.status as ProjectVariantStatus,
                                      comments: {
                                          nodes: updatedVariant.comments.nodes.map((comment) => ({
                                              ...comment,
                                              hasUnsavedChanges: 'None',
                                          })),
                                      },
                                  }
                                : variant,
                        ),
                    );
                    break;
            }
        }

        resetVariantForm(false);
        setActiveCard({ id: '', label: '', status: 'View' });
    }

    async function onCreateComment(variantId: string, text: string) {
        const user = toValue(currentUser);
        const newComment = {
            id: uuidv4(),
            text,
            type: 'user-text' as FeedCommentType,
            createdAt: DateTime.now().toISO(),
            createdBy: user.id,
            createdByName: user.name,
            createdByIcon: user.icon?.original ?? '',
            oldStatus: '',
            newStatus: '',
        };
        setCommentsToCreate([newComment, ...toValue(commentsToCreate)]);
        setVariantsRef(
            variantsRef.value.map((v) =>
                v.id === variantId
                    ? {
                          ...v,
                          comments: {
                              nodes: [
                                  {
                                      ...newComment,
                                      variantId,
                                      updatedAt: '',
                                      updatedBy: null,
                                      updatedByName: '',
                                      hasUnsavedChanges: 'New',
                                  },
                                  ...v.comments.nodes,
                              ],
                          },
                      }
                    : v,
            ),
        );
        setNewCommentText('');
    }

    async function onDeleteComment(variantId: string, commentId: string) {
        // differentiate between temporary comment and already saved comment on deletion
        const createCommentIds = commentsToCreate.value.map((c) => c.id);
        if (createCommentIds.includes(commentId)) {
            setCommentsToCreate(commentsToCreate.value.filter((c) => c.id !== commentId));
            setVariantsRef(
                variantsRef.value.map((v) =>
                    v.id === variantId
                        ? {
                              ...v,
                              comments: {
                                  nodes: v.comments.nodes.filter((c) => c.id !== commentId),
                              },
                          }
                        : v,
                ),
            );
        } else {
            setCommentsToDelete([...commentsToDelete.value, { id: commentId }]);
            setVariantsRef(
                variantsRef.value.map((v) =>
                    v.id === variantId
                        ? {
                              ...v,
                              comments: {
                                  nodes: v.comments.nodes.map((c) =>
                                      c.id === commentId
                                          ? {
                                                ...c,
                                                hasUnsavedChanges: 'Deleted',
                                            }
                                          : c,
                                  ),
                              },
                          }
                        : v,
                ),
            );
        }
    }

    async function onUpdateComment(commentId: string, text: string) {
        const createCommentIds = commentsToCreate.value.map((c) => c.id);
        const updateCommentIds = commentsToUpdate.value.map((c) => c.id);
        if (createCommentIds.includes(commentId)) {
            // changed comment is a newly created comment
            commentsToCreate.value = commentsToCreate.value.map((comment) =>
                comment.id === commentId ? { ...comment, text } : comment,
            );
        } else if (updateCommentIds.includes(commentId)) {
            commentsToUpdate.value = commentsToUpdate.value.map((cu) =>
                cu.id === commentId
                    ? {
                          ...cu,
                          text,
                      }
                    : cu,
            );
        } else {
            commentsToUpdate.value = [
                ...commentsToUpdate.value,
                {
                    id: commentId,
                    text,
                    updatedAt: DateTime.now().toISO(),
                    updatedBy: currentUser.id,
                    updatedByName: currentUser.name,
                },
            ];
        }
        setVariantsRef(
            variantsRef.value.map((v) =>
                v.id === toValue(variantRef).id
                    ? {
                          ...v,
                          comments: {
                              nodes: v.comments.nodes.map((c) =>
                                  c.id === commentId
                                      ? {
                                            ...c,
                                            text,
                                            hasUnsavedChanges: 'Edited',
                                        }
                                      : c,
                              ),
                          },
                      }
                    : v,
            ),
        );
    }

    function onUndoDeletingComment(commentId: string) {
        commentsToDelete.value = commentsToDelete.value.filter((comment) => comment.id !== commentId);
        const updateCommentIds = commentsToUpdate.value.map((c) => c.id);
        let hasUnsavedChanges: CommentChangesStatus = 'None';
        if (updateCommentIds.includes(commentId)) {
            hasUnsavedChanges = 'Edited';
        }
        setVariantsRef(
            variantsRef.value.map((v) =>
                v.id === toValue(variantRef).id
                    ? {
                          ...v,
                          comments: {
                              nodes: v.comments.nodes.map((c) =>
                                  c.id === commentId
                                      ? {
                                            ...c,
                                            hasUnsavedChanges: hasUnsavedChanges,
                                        }
                                      : c,
                              ),
                          },
                      }
                    : v,
            ),
        );
    }

    return {
        variantFormData,
        variantFormErrors,
        saveButtonDisabledExplanation,
        formChanged,
        commentsToCreate,
        commentsToUpdate,
        commentsToDelete,
        handleNameFieldInput,
        handleNameFieldChange,
        handleAddDateCommentEntryInput,
        resetVariantForm,
        onCreateComment,
        onDeleteComment,
        onUpdateComment,
        onUndoDeletingComment,
        onSave,
    };
}
