import { computed, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { AllocationPhaseUpdate, LocationType } from './Timeline.types';
import { TimelineAllocationResponse, TimelineVariant } from './allocation/types';

export function getTransparencyVariant(highlightedVariantId: string, variantId: string, isCurrent: boolean) {
    if (highlightedVariantId === variantId || highlightedVariantId === 'No-Highlight-Id') {
        if (isCurrent) {
            return 'Solid' as const;
        } else {
            return 'Outer-Semi-Transparent' as const;
        }
    }
    return 'Semi-Transparent' as const;
}

export function getSectionId(locationType: LocationType | string): string {
    switch (locationType) {
        case 'Hallenausstellungsfläche':
            return 'section-id-hallenausstellungsflächen';

        case 'Boulevardsektion':
            return 'section-id-boulevard-sektionen';

        case 'Passage':
        case 'Passage inkl. Durchfahrtstor':
            return 'section-id-passagen';

        case 'Eingang/Zugang':
            return 'section-id-eingänge-zugänge';

        case 'Service-Center':
            return 'section-id-service-center';

        case 'Konferenzraum':
            return 'section-id-konferenzräume';

        case 'Büro/Besprecher (ZBV intern)':
            return 'section-id-büros-besprecher-intern';

        case 'Lager (ZBV intern)':
            return 'section-id-lagerräume-intern';

        case 'Büro/Besprecher (ZBV extern)':
            return 'section-id-büros-besprecher-extern';

        case 'Lager (ZBV extern)':
            return 'section-id-lagerräume-extern';

        case 'Parkfläche (intern)':
        case 'Parkfläche (extern)':
            return 'section-id-parking';

        case 'Außenfläche (intern)':
        case 'Außenfläche (extern)':
            return 'section-id-outdoor-area';

        case 'Geländezufahrt/-zugang':
            return 'section-id-entrance';

        case 'Fahrstraße (intern)':
        case 'Fahrstraße (extern)':
            return 'section-id-road';

        default:
            throw new Error(`Unknown location type: ${locationType}`);
    }
}

export function validateAllocationUpdateResponse(
    allocation: TimelineAllocationResponse | null | undefined,
    remainingPhases: (AllocationPhaseUpdate & { dayOffsetCount: number })[],
) {
    if (!allocation) {
        throw new Error('No response from allocation update');
    }
    const phases = allocation.phases.nodes;
    if (!phases) {
        throw new Error('Updated phases are missing in response');
    }
    if (phases.length !== remainingPhases.length) {
        throw new Error('Unexpected mismatch of frontend phases length and updated phases in response');
    }

    const updatedPhasesResponse = allocation.phases.nodes;

    const confirmedPhases = remainingPhases.map((phase, index) => {
        const specialization =
            phase.newSpecialization !== phase.specialization ? phase.newSpecialization : phase.specialization;
        return {
            ...phase,
            id: updatedPhasesResponse[index].id,
            cellSpan: phase.cellSpanBefore + phase.cellSpan + phase.cellSpanAfter,
            cellSpanBefore: 0,
            cellSpanAfter: 0,
            specialization,
        };
    });

    return confirmedPhases;
}

export function useTimelineRouteParams(variantsRef: Ref<TimelineVariant[]>) {
    const route = useRoute();

    const visibleIdsFromRoute = computed(() => {
        if ('visible' in route.query) {
            const indexString = route.query.visible as string;
            const visibleIndexes = indexString.split(',').map((s) => Number(s));
            return variantsRef.value.filter((v) => visibleIndexes.includes(v.index)).map((v) => v.id);
        }
        return [];
    });

    const highlightedIdFromRoute = computed(() => {
        if ('highlight' in route.query) {
            const index = Number(route.query.highlight as string);
            return variantsRef.value.find((v) => v.index === index)?.id ?? 'No-Highlight-Id';
        }
        return 'No-Highlight-Id';
    });

    return {
        visibleIdsFromRoute,
        highlightedIdFromRoute,
    };
}
