<template>
    <UpdateRequestBanner v-if="showUpdateRequestBanner" @click="onUpdateRequestBannerClick" />
    <div class="w-full h-screen flex relative">
        <NavigationSidebar
            class="flex-none"
            :user="user"
            :is-expanded="isExpanded"
            :deployment-info="deploymentInfo"
            :show-environment="config.showEnvName"
            :main-navigation-headline="$t('main-navigation-headline')"
            :user-section-headline="$t('user-section-headline')"
            :deployment-info-headline="$t('deployment-info-headline')"
            @toggle-is-expanded="isExpanded = !isExpanded"
            @logout="auth.logout()"
        >
            <template v-if="isExpanded">
                <NavigationItem target="/">
                    <AppsIcon class="w-5 h-5" />
                    {{ $t('dashboard') }}
                </NavigationItem>

                <NavigationItem target="/locations">
                    <BuildingIcon class="w-5 h-5" />
                    {{ $t('location-wiki') }}
                </NavigationItem>

                <NavigationItem target="/events">
                    <BallotIcon class="w-5 h-5" />
                    {{ $t('event-wiki') }}
                </NavigationItem>

                <NavigationItem target="/scenarios">
                    <BrainIcon class="w-5 h-5" />
                    {{ $t('scenarios') }}
                </NavigationItem>

                <NavigationItem target="/allocation-designer">
                    <TacticIcon class="w-5 h-5" />
                    {{ $t('allocation-designer') }}
                </NavigationItem>

                <NavigationItem target="/projects">
                    <ConstructionIcon class="w-5 h-5" />
                    {{ $t('project-wiki') }}
                </NavigationItem>

                <NavigationItem target="/external-events">
                    <AirplaneIcon class="w-5 h-5" />
                    {{ $t('external-events') }}
                </NavigationItem>

                <NavigationItem target="/calendar">
                    <EventIcon class="w-5 h-5" />
                    {{ $t('calendar') }}
                </NavigationItem>

                <NavigationItem target="/users">
                    <UsersIcon class="w-5 h-5" />
                    {{ $t('users') }}
                </NavigationItem>
            </template>

            <template v-if="!isExpanded">
                <Tooltip>
                    <template #trigger="tooltipProps">
                        <NavigationItem target="/">
                            <AppsIcon class="w-5 h-5" :aria-labelledby="tooltipProps['aria-labelledby']" />
                        </NavigationItem>
                    </template>

                    <template #text>{{ $t('dashboard') }}</template>
                </Tooltip>

                <Tooltip>
                    <template #trigger="tooltipProps">
                        <NavigationItem target="/locations">
                            <BuildingIcon class="w-5 h-5" :aria-labelledby="tooltipProps['aria-labelledby']" />
                        </NavigationItem>
                    </template>

                    <template #text>{{ $t('location-wiki') }}</template>
                </Tooltip>

                <Tooltip>
                    <template #trigger="tooltipProps">
                        <NavigationItem target="/events">
                            <BallotIcon class="w-5 h-5" :aria-labelledby="tooltipProps['aria-labelledby']" />
                        </NavigationItem>
                    </template>

                    <template #text>{{ $t('event-wiki') }}</template>
                </Tooltip>

                <Tooltip>
                    <template #trigger="tooltipProps">
                        <NavigationItem target="/scenarios">
                            <BrainIcon class="w-5 h-5" :aria-labelledby="tooltipProps['aria-labelledby']" />
                        </NavigationItem>
                    </template>

                    <template #text>{{ $t('scenarios') }}</template>
                </Tooltip>

                <Tooltip>
                    <template #trigger="tooltipProps">
                        <NavigationItem target="/allocation-designer">
                            <TacticIcon class="w-5 h-5" :aria-labelledby="tooltipProps['aria-labelledby']" />
                        </NavigationItem>
                    </template>

                    <template #text>{{ $t('allocation-designer') }}</template>
                </Tooltip>

                <Tooltip>
                    <template #trigger="tooltipProps">
                        <NavigationItem target="/projects">
                            <ConstructionIcon class="w-5 h-5" :aria-labelledby="tooltipProps['aria-labelledby']" />
                        </NavigationItem>
                    </template>

                    <template #text>{{ $t('projects') }}</template>
                </Tooltip>

                <Tooltip>
                    <template #trigger="tooltipProps">
                        <NavigationItem target="/external-events">
                            <AirplaneIcon class="w-5 h-5" :aria-labelledby="tooltipProps['aria-labelledby']" />
                        </NavigationItem>
                    </template>

                    <template #text>{{ $t('external-events') }}</template>
                </Tooltip>

                <Tooltip>
                    <template #trigger="tooltipProps">
                        <NavigationItem target="/calendar">
                            <EventIcon class="w-5 h-5" :aria-labelledby="tooltipProps['aria-labelledby']" />
                        </NavigationItem>
                    </template>

                    <template #text>{{ $t('calendar') }}</template>
                </Tooltip>

                <Tooltip>
                    <template #trigger="tooltipProps">
                        <NavigationItem target="/users">
                            <UsersIcon class="w-5 h-5" :aria-labelledby="tooltipProps['aria-labelledby']" />
                        </NavigationItem>
                    </template>

                    <template #text>{{ $t('users') }}</template>
                </Tooltip>
            </template>
        </NavigationSidebar>

        <div class="w-full h-screen overflow-y-auto">
            <EnvironmentWarnBanner v-if="envNameMismatch" environment="dev" />
            <router-view />
        </div>
    </div>
</template>

<script setup lang="ts">
import auth from '@/auth/auth';
import EnvironmentWarnBanner from '@/components/EnvironmentWarnBanner/EnvironmentWarnBanner.vue';
import AirplaneIcon from '@/components/Icon/AirplaneIcon.vue';
import AppsIcon from '@/components/Icon/AppsIcon.vue';
import BallotIcon from '@/components/Icon/BallotIcon.vue';
import BrainIcon from '@/components/Icon/BrainIcon.vue';
import BuildingIcon from '@/components/Icon/BuildingIcon.vue';
import ConstructionIcon from '@/components/Icon/ConstructionIcon.vue';
import EventIcon from '@/components/Icon/EventIcon.vue';
import TacticIcon from '@/components/Icon/TacticIcon.vue';
import UsersIcon from '@/components/Icon/UsersIcon.vue';
import NavigationItem from '@/components/NavigationSidebar/NavigationItem.vue';
import NavigationSidebar, { DeploymentInfo } from '@/components/NavigationSidebar/NavigationSidebar.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import UpdateRequestBanner from '@/components/UpdateRequestBanner/UpdateRequestBanner.vue';
import { config } from '@/config';
import { getEnvironmentLong, getEnvironmentShort } from '@/utils/environment';
import hashString from '@/utils/hash';
import { getImageUrl } from '@/utils/s3';
import { useFetch } from '@vueuse/core';
import { useFluent } from 'fluent-vue';
import { DateTime } from 'luxon';
import { computed, ref, watchEffect } from 'vue';

// token refresh for logged-in users every minute
auth.useRegularTokenRefresh();

const envNameMismatch = config.envName === 'devdb';

const { $t } = useFluent();

type VersionJsonResponse = {
    job: {
        startedAt: string;
        url: string;
    };
    commit: {
        branch: string;
        sha: string;
        shortSha: string;
        timestamp: string;
        url: string;
    };
};

const isExpanded = ref(true);
const showUpdateRequestBanner = ref(false);
const versionJsonTimestamp = ref<string>('');

const deploymentInfo = ref<DeploymentInfo>({
    status: 'Loading',
    loadingMessage: $t('loading-deployment-info'),
    environmentLong: getEnvironmentLong(Object.create(globalThis.import_meta_env || null).ENV_NAME),
    environmentShort: getEnvironmentShort(Object.create(globalThis.import_meta_env || null).ENV_NAME),
});

function handleReload() {
    localStorage.setItem('version', versionJsonTimestamp.value);
    showUpdateRequestBanner.value = false;
}

const { isFetching, data, error, execute } = useFetch<string>(config.versionJsonUrl);

// fetch version json every 10 minutes
setInterval(execute, 1000 * 60 * 10);
window.onbeforeunload = () => handleReload();

function checkVersionTimestamp() {
    const localTimestamp = localStorage.getItem('version');
    if (!localTimestamp) {
        localStorage.setItem('version', versionJsonTimestamp.value);
    } else {
        if (versionJsonTimestamp.value !== localTimestamp) {
            showUpdateRequestBanner.value = true;
        }
    }
}

watchEffect(() => {
    if (isFetching.value) {
        deploymentInfo.value = {
            status: 'Loading',
            loadingMessage: $t('loading-deployment-info'),
            environmentLong: getEnvironmentLong(Object.create(globalThis.import_meta_env || null).ENV_NAME),
            environmentShort: getEnvironmentShort(Object.create(globalThis.import_meta_env || null).ENV_NAME),
        };
    }

    if (data.value) {
        try {
            const versionJson = JSON.parse(data.value) as VersionJsonResponse;
            const datetime = DateTime.fromISO(versionJson.commit.timestamp);
            versionJsonTimestamp.value = versionJson.commit.timestamp;

            deploymentInfo.value = {
                status: 'Success',
                commitShortHash: versionJson.commit.shortSha,
                commitTimestampLong: datetime.toLocaleString({
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                }),
                commitTimestampShort: datetime.toLocaleString({
                    day: '2-digit',
                    month: '2-digit',
                }),
                commitUrl: versionJson.commit.url,
                environmentLong: deploymentInfo.value.environmentLong,
                environmentShort: deploymentInfo.value.environmentShort,
            };

            // check timestamp to create a warning if the frontend needs a page refresh
            checkVersionTimestamp();
        } catch (error) {
            throw new Error(`Parsing the version.json failed with error ${error}`);
        }
    }

    if (error.value) {
        deploymentInfo.value = {
            status: 'Error',
            errorMessage: $t('loading-deployment-info-failed'),
            environmentLong: deploymentInfo.value.environmentLong,
            environmentShort: deploymentInfo.value.environmentShort,
        };
        throw new Error(`Error on fetching deployment info: ${error.value}`);
    }
});

const currentUser = auth.getUser();

// Implementation copied from ProfilePicture.vue
function getAvatarBackgroundColorStyles(userName: string): string {
    // 500 variants of all tailwind colors https://tailwindcss.com/docs/customizing-colors
    const colors = [
        '#64748b',
        '#6b7280',
        '#71717a',
        '#737373',
        '#78716c',
        '#ef4444',
        '#f97316',
        '#f59e0b',
        '#eab308',
        '#84cc16',
        '#22c55e',
        '#10b981',
        '#14b8a6',
        '#06b6d4',
        '#0ea5e9',
        '#3b82f6',
        '#6366f1',
        '#8b5cf6',
        '#a855f7',
        '#d946ef',
        '#ec4899',
        '#f43f5e',
    ];

    return `background-color: ${colors[hashString(userName) % colors.length]};`;
}

const user = computed(() => {
    return {
        name: currentUser.name,
        avatarUrl: getImageUrl(currentUser.avatarUrl),
        avatarAlt: `${currentUser.firstName.at(0)?.toUpperCase()}${currentUser.lastName.at(0)?.toUpperCase()}`,
        avatarBackgroundColorStyles: getAvatarBackgroundColorStyles(currentUser.name),
        profileLink: '/me',
    };
});

function onUpdateRequestBannerClick() {
    handleReload();
    window.location.reload();
}
</script>
